@import '../../styles/variables.scss';

.popup {
	&__container {
		width: 100%;
		position: fixed;
		z-index: 1060;
		inset: 0;
		height: 100%;
		padding: 0.625em 0.625em 0;
		overflow-x: hidden;
		transition: background-color 0.1s;
		-webkit-overflow-scrolling: touch;
		background: rgba(0, 0, 0, 0.4);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__content {
		top: 0;
		position: absolute;
		border: none;
		border-radius: 5px;
		background: #fff;
		display: flex;
		flex-direction: column;
		padding: 20px;
		width: 100%;
		max-width: 600px;
		margin: 50px 0 20px 0;
		h3,
		p {
			padding: 0 20px;
		}
	}
	&__btn__close {
		margin: 0 0 0.5rem auto;
	}

	&__button {
		margin: 2rem auto;
	}

	&__max--width {
		margin: 0 auto;
		max-width: 400px;
		width: 100%;
	}
	&__totals {
		margin-top: 16px;
	}
}
.cobertura-payment-content {
	&.not__margin--top {
		margin-top: 16px;
	}
}
