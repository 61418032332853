.summary__information {
	&__container {
		display: flex;
		flex-direction: column;
	}

	&__content {
		display: flex;
		justify-content: space-between;
	}

	&__label,
	&__value {
		font-size: 16px;
		margin-bottom: 11px;
		&.line-through {
			text-decoration: line-through;
		}
		&.not--margin {
			margin-bottom: 0;
		}
		&.bold {
			font-family: Inter-Bold, sans-serif;
			font-weight: bold;
		}
	}
}
