@import '../styles/variables.scss';

.banner a.mas-info {
	margin-top: 10px;
}

.experiencia-connect {
	margin: 60px 0;

	@media (min-width: $bp--md) {
		margin: 120px 0;
	}
}