@import './styles/variables.scss';

.container__app {
	position: relative;
}

.App {
	@media (max-width: $bp--md) {
		margin-top: $header-bar--height--mobile * 2;
	}
}

.App-header {
	// prevent drop shadow cleeding in top of this
	position: relative;
	z-index: 1;
	background-color: $white;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

a {
	text-decoration: none;
	color: $orange;

	&:hover {
	}

	&.underline {
		display: inline-block;
		position: relative;

		&::after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 0;
			height: 1px;
			background-color: $orange;
			transition: width $fast;
		}

		&:hover::after {
			width: 100%;
		}
	}
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

img {
	max-width: 100%;
	height: auto;
}

h1,
.h1 {
	color: $dark-gray;
	font-family: 'TradeGothicLTStd-BoldExt';
	font-size: 2.6rem;
	padding: 0;
	margin-bottom: 0.25em;
	line-height: 1.2;
	font-weight: 400;

	@media only screen and (min-width: $screen-md-min) {
		font-size: 6rem;
	}
}

h2 {
	color: $dark-gray;
	font-size: 4rem;
	font-family: 'TradeGothicLTStd-BoldExt';
	line-height: 52px;
	padding: 0;
	margin-bottom: 0.25em;
	font-weight: 400;
	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-22;
		line-height: 32px;
	}
}

h3 {
	color: $light-gray;
	font-family: 'TradeGothicLTStd-BoldExt';
	font-size: $font-28;
	padding: 0;
	margin-bottom: 0.25em;
	font-weight: 400;
}

h4 {
	color: $light-gray;
	font-family: 'TradeGothicLT';
	font-size: $font-18;
	padding: 0;
	margin-bottom: 0.25em;
}

h5 {
	color: $footer-h4;
	font-family: 'TradeGothicLT-BoldCondTwenty';
	font-size: 1.6rem;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	line-height: 19px;
}

.orange-title {
	color: $orange;
	font-family: 'TradeGothicLT-BoldCondTwenty';
	font-size: 1.8rem;
	text-transform: uppercase;
	margin-bottom: 30px;

	@media only screen and (min-width: $screen-md-min) {
		font-size: 2.4rem;
	}
}

p {
	padding: 0;
	margin-bottom: 2em;
	@media only screen and (max-width: $screen-md-min) {
		font-size: 1.6rem;
	}
}

ul {
	list-style: none;
}

.button,
button {
	display: block;
	width: 25rem;
	padding: 1.2rem 2rem;
	font-family: 'TradeGothicLT-BoldCondTwenty';
	text-transform: uppercase;
	text-align: center;
	color: $white;
	background-color: $orange;
	transition: background-color $fast;
	font-size: inherit;
	border: none;
	line-height: inherit;
	cursor: pointer;

	&:hover {
		background-color: $orange-2;
	}
}

.button-disabled {
	cursor: none;
	background-color: $light-gray-2;
	&:hover {
		background-color: $light-gray-2;
	}
}

.btnAlert {
	font-size: 1.7rem;
}

.text-center,
.ctr {
	text-align: center;
}

.ctr {
	margin: 0 auto;
}

.text-center-mobile,
.ctr-mobile {
	@media only screen and (max-width: $screen-md-min) {
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}
}

.float-left {
	float: left;
	@media only screen and (max-width: $screen-md-min) {
		float: none;
	}
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.white {
	color: $white;
}

.container {
	margin: 0 auto;
	max-width: 1200px;
	padding: 0 20px;

	@media (min-width: $screen-md-min) {
		padding: 0 40px;
	}

	&.full-width {
		max-width: none;
	}
}

.full-width {
	width: 100%;

	&--mobile {
		@media (max-width: $bp--md) {
			width: 100%;
		}
	}
}

.quarter-with {
	width: 25%;

	&--mobile {
		@media (max-width: $bp--md) {
			width: 25%;
		}
	}
}

.containerInputs {
	margin-top: 24px;
}

.inputFixed {
	border: 1px solid #c1c4c8;
	padding: 7px;
	border-radius: 6px;
	padding: 12px 12px 10px;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding-right: 60px;
	height: 4.1876em;
}

.buttonFixed {
	background-color: $white;
	border: none;
	color: #53565a;
	padding: 1.2rem 2rem;
	cursor: pointer;
	font-family: 'TradeGothicLT-BoldCondTwenty';
	height: 3.4em;
	font-size: 16px;
	text-transform: uppercase;
	float: right;
	position: relative;
	top: -55px;
	border-left: 1px solid #c1c4c8;
	border-right: 1px solid #c1c4c8;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}

@media not all and (min-resolution: 0.001dpcm) {
	@supports (-webkit-appearance: none) {
		.buttonFixed {
			background-color: $white;
			border: none;
			color: #53565a;
			padding: 0.9rem 2rem;
			cursor: pointer;
			font-family: 'TradeGothicLT-BoldCondTwenty';
			height: 2.75em;
			font-size: 16px;
			text-transform: uppercase;
			float: right;
			position: relative;
			top: -45px;
			border-left: 1px solid #c1c4c8;
			border-right: 1px solid #c1c4c8;
			border-top-right-radius: 6px;
			border-bottom-right-radius: 6px;
		}
	}
}

.buttonFixedOrange {
	background-color: $orange;
	border: none;
	color: $white;
	padding: 1.2rem 2rem;
	cursor: pointer;
	font-family: 'TradeGothicLT-BoldCondTwenty';
	height: 3.4em;
	font-size: 16px;
	text-transform: uppercase;
	float: right;
	position: relative;
	top: -55px;
	border-left: 1px solid #c1c4c8;
	border-right: 1px solid #c1c4c8;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}
.buttonFixed input[type='text']:focus {
	border: 1px solid rgba(81, 203, 238, 1) !important;
}

.errorInput {
	border: 3px solid #ca651a;
	padding: 7px;
	border-radius: 6px;
	padding: 12px 12px 10px;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding-right: 60px;
	height: 4.1876em;
}

.pago-descuento {
	color: #50842b;
	font-size: 1em;
	font-family: 'TradeGothicLT-BoldCondTwenty';
	text-transform: uppercase;
}

.narrow {
	max-width: 580px;
	margin: 0 auto;
}

.hero {
	padding: 140px 0;

	&.dark {
		background-color: $dark-gray;
		color: $warm-grey;

		h2 {
			color: inherit;
		}
	}

	&.tall {
		padding: 100px 0;
	}
}

.margin-left-8 {
	margin-left: 8px;
}
.margin-left-15 {
	margin-left: 15px;
}
.margin-left-20 {
	margin-left: 20px;
}
.margin-top-7 {
	margin-top: 7px;
}
.margin-top-12 {
	margin-top: 12px;
}

.margin-top-26 {
	margin-top: 26px;
}
.margin-top-12 {
	margin-top: 12px;
}
.margin-top-10 {
	margin-top: 10px;
}
.margin-top-20 {
	margin-top: 20px;
}

.margin-top-30 {
	margin-top: 30px;
}

.margin-top-40 {
	margin-top: 40px;
}
.margin-top-50 {
	margin-top: 50px;
}
.margin-top-60 {
	margin-top: 60px;
}

.margin-top-112 {
	margin-top: 112px !important;
}

.margin-top-120 {
	margin-top: 120px;
}

.margin-bottom-80 {
	margin-bottom: 80px;
}

.padding-top-60 {
	padding-top: 60px;
}

.toast-margin-bump--mobile {
	margin-top: $toast-margin-bump--mobile;
}

.align-self-center {
	align-self: center;
}
.mobile {
	display: none;
}
.desktop {
	display: block;
}
@media only screen and (max-width: $screen-md-min) {
	.mobile {
		display: block;
	}
	.desktop {
		display: none !important;
	}
}

/* Overiding the above?? 
@media only screen and (max-width: $screen-xlg-min) {
	.mobile {
		display: block;
	}
	.desktop {
		display: none;
	}
}
*/

.icon-btn {
	// Outline and Background
	width: 3.2rem;
	height: 3.2rem;
	border: 1px solid #cbcdcf;
	margin-left: 10px;
	border-radius: 1em;
	text-indent: -999em;
	overflow: hidden;
	transition: background-color $fast;
	background-color: $white;

	// Icon image
	&::before {
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		background: url('images/svg/phone-icon.svg') center no-repeat;
	}

	&.chat::before {
		background-image: url('images/svg/chat-icon.svg');
	}

	&:hover {
		background-color: $orange;
		border-color: $orange;

		&::before {
			filter: grayscale(100) contrast(100) invert(100);
		}
	}
}

.bg--light-gray-3 {
	background-color: $light-gray-3;
}

.color-dark-gray {
	color: $dark-gray;
}

.qty-icon {
	display: block;
	padding: 4px;
	width: 20px;
	height: 20px;
	border: 1px solid $light-gray-4;
	border-radius: 50%;
	transition: background-color $fast, border-color $fast;

	svg {
		display: block;

		path,
		rect {
			transition: fill $fast;
		}
	}

	&:hover {
		border-color: $orange-2;
		background-color: $orange-2;

		svg {
			path,
			rect {
				fill: white;
			}
		}
	}
}

.uppercase {
	text-transform: uppercase;
}

.light-gray {
	color: $light-gray;
}

.orange {
	color: $orange;
}

.orange-2 {
	color: $orange-2 !important;
}

.line {
	width: 100%;
	height: 1px;
	background-color: $cool-gray;
}

.pointer {
	cursor: pointer;
}
.background-green {
	background-color: $green;
}

.background-orange {
	background-color: $orange;
}

input[type='text'] {
	color: $dark-gray;
}

.no-focus-outline a:focus,
.no-focus-outline button:focus,
.no-focus-outline [role='button']:focus {
	outline: none;
}

// Material UI Styles
// Should be moved to cutome theme? (https://material-ui.com/customization/theming/)
.MuiMenuItem-root {
	font-size: 1.4rem !important;
}

//HACK make entire dropdown clickable
//This shuld not be necessary??
.MuiSvgIcon-root.dropdown {
	position: absolute;
	right: 0;
	z-index: -1;
}

// Remove extra space next to checkbox
.pago-terminos-div .MuiFormControlLabel-root {
	margin-right: 0;
}

div.MuiInputBase-root {
	font-family: 'TradeGothicLT';
	font-size: 1.6rem;
	margin-bottom: 0;
	background-color: transparent;
	border: 1px solid $cool-gray;
	border-radius: 6px;
}
// FOCUSED
div.MuiInputBase-root.Mui-focused {
	border: 3px solid $focus-blue;
	color: $light-gray;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
//ERROR
div[errorinput='1'] div.MuiInputBase-root {
	border: 3px solid $orange;
	background-color: rgba($error-red, 0.1);
}

div.MuiFormControl-root {
	margin-top: 24px;
	width: 100%;
}

div.MuiFilledInput-underline:before {
	border-bottom: none;
}

div.MuiFilledInput-underline:after {
	border-bottom: none;
}

div.MuiSelect-filled.MuiSelect-filled {
	color: $light-gray-2;
	text-align: left;
}

div.MuiInputBase-root.Mui-focused,
div.MuiFilledInput-root:hover {
	background-color: transparent;
	border-color: $focus-blue;
}

div.MuiFilledInput-underline:hover:before {
	background-color: transparent;
}

div.MuiFilledInput-underline:hover:before {
	background-color: transparent;
	border-bottom: none;
}

div.MuiFilledInput-underline.Mui-disabled {
	background-color: $cool-gray;
}

div.MuiFilledInput-underline.Mui-disabled:before {
	border-bottom-style: none;
}

div.MuiFilledInput-underline.Mui-disabled:hover {
	background-color: $cool-gray;
}

.PrivateSwitchBase-root-1 {
	padding: 0 !important; // Disable circle target effect
}

.MuiCheckbox-colorSecondary.Mui-checked {
	color: $orange-2 !important;
}

label.MuiFormControlLabel-root .MuiFormControlLabel-label {
	font-size: 12px;
	font-family: 'TradeGothicLT';
}

.textfield-error-label {
	color: $orange;
	font-family: 'TradeGothicLT';
	font-size: 11px;
	display: block;
	padding: 0;
	margin-left: 5px;
	margin-top: 5px !important;
	text-align: left;
	letter-spacing: 0;
}

.arrow--left {
	transform: rotate(180deg);
}

.arrow--small {
	height: 10px;
}

.v-ctr {
	display: flex;
	align-items: center;
}

// FUNNEL
.funnel__main {
	padding: 20px;

	@media (min-width: $screen-sm-min) {
		padding: 30px;
	}

	@media (min-width: $screen-md-min) {
		display: flex;
		height: $funnel-main-height;
		padding: 50px;
		overflow-y: auto;
	}
}

.funnel__main__inner {
	flex-grow: 1;
	max-width: 960px;

	.funnel__cols {
		margin-top: 15px;

		@media (min-width: $screen-md-min) {
			display: flex;

			.funnel__col {
				flex: 0 0 50%;
			}

			.funnel__col-1 {
				padding-right: 30px;
				border-right: 1px solid $cool-gray;

				h3 {
					margin-bottom: 20px;
					color: $dark-gray;
					@media only screen and (max-width: $screen-md-min) {
						margin-bottom: 10px;
						font-size: $font-18;
						margin-top: 10px;
					}
				}

				&.no-divider {
					border-right: none;
				}
			}

			.funnel__col-2 {
				padding-left: 30px;
			}
		}
	}

	@media (min-width: $screen-md-min) {
		margin: auto;

		&.full-width {
			max-width: none;
		}

		&.two-col {
			display: flex;
			flex-direction: row-reverse;
		}

		&__left {
			width: 50%;

			div {
				max-width: 480px;
			}

			justify-content: end;
		}

		&__right {
			width: 50%;
		}
	}

	.text .inner {
		max-width: 480px;
		margin: 50px auto 0 auto;

		@media (min-width: $screen-md-min) {
			margin: auto 50px auto;
		}
	}

	.illustration {
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
			max-height: calc(100vh - 278px);
		}
	}
}

.funnel-slide-container-bottom {
	position: relative; //Fix nav dots fixed on screen
}

main {
	@media (max-width: $screen-lg-min) and (orientation: portrait) {
		.banner {
			background-size: 150%;
		}
	}
}

.whatsapp__container {
	z-index: 2;
	background-color: $orange;
	border-radius: 24px;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	letter-spacing: 0.25px;
	transition: all 0.08s linear;
	cursor: pointer;
	padding-right: 15px;
	padding-left: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	font-size: 16px;
	margin-top: 15px;
	box-shadow: rgb(0 0 0 / 20%) 0px 0px 0.428571rem 0px;
	font-family: 'TradeGothicLT-Bold';
	position: fixed;
	bottom: 80px;
	right: 30px;
	img {
		height: 40px;
		width: 40px;
	}
}
