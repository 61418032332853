@import '../../../styles/variables.scss';

.nc-wrapper {
	display: flex;
	flex-direction: column;
	align-self: center;
}

.nuestros-clientes {
	background: $dark-gray;
	margin-top: 0;
	padding: 80px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
	@media only screen and (max-width: $screen-md-min) {
		padding-top: 60px;
	}
}
.nc-title {
	margin: 0 auto;
	margin-bottom: 50px;
	max-width: 720px;
	font-size: 4.2rem;
	line-height: 1.25;
	font-family: 'TradeGothicLTStd-BoldExt';
	text-align: center;

	@media only screen and (max-width: $screen-md-min) {
		font-size: 2.2rem;
		line-height: 32px;
	}
}

.nc-orange-title {
	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-18;
		letter-spacing: 0.56px;
		line-height: 22px;
		text-align: center;
	}
}

.nc-name {
	display: flex;
	color: $cool-gray;
	font-family: 'TradeGothicLT-BoldTwo';
	font-size: $font-18;
	letter-spacing: -0.11px;
	line-height: 24px;
	margin-left: 15px;
	align-items: center;
}

.nc-clients {
	display: flex;
	flex-direction: row;
	align-self: center;
	font-family: 'TradeGothicLT-BoldTwo';
}

.nc-arrows {
	display: flex;
	width: 50px;
	height: 35px;
	align-self: center;

	&--left {
		margin-left: 24px;
		justify-content: flex-end;
	}
	&--right {
		margin-right: 24px;
	}

	@media (min-width: $screen-md-min) {
		&.mobile {
			display: none;
		}
	}
}

$dots: 5;
$dotSize: 0.4;
$dotMargin: 0.5;
$duration: 0.3s;
$timingEase: cubic-bezier(0.51, 0.92, 0.24, 1);
$timingBounce: cubic-bezier(0.51, 0.92, 0.24, 1.15);
.nc-slide {
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.nc-slide-container__slides {
	position: relative;
	width: $dots * 100%;
	height: 100%;
	transition: transform $duration $timingEase;
	will-change: transform;
}

.nc-slide-container__slide {
	float: left;
	width: calc(100% / $dots);
	height: 100%;
}

.nc-slide-container__dots {
	display: flex;
	position: absolute;
}

.nc-slide-container__dot {
	display: block;
	margin: 0 #{$dotMargin}em;
	width: #{$dotSize}em;
	height: #{$dotSize}em;
	background: $dot;
	border-radius: 100px;
}

.nc-slide-container__indicator {
	@extend .nc-slide-container__dot;

	position: absolute;
	background: $orange;
	width: auto;

	&--left {
		transition: left $duration $timingBounce, right $duration calc($duration/3) $timingBounce;
	}

	&--right {
		transition: left $duration calc($duration/3) $timingBounce, right $duration $timingBounce;
	}
}

//New
.nc-slide-container {
	display: flex;
	width: 100%;
	flex-direction: column;
	@for $i from 0 to $dots {
		$slide: calc(100% / $dots);
		$left: $slide * $i;

		&[data-pos='#{ $i }'] &__slides {
			transform: translateX(-$left);
		}
	}

	@for $i from 0 to $dots {
		$dot: $dotSize + $dotMargin * 2;
		$left: $dot * $i;
		$right: $dot * ($dots - $i) - $dot;

		&[data-pos='#{ $i }'] &__indicator {
			left: #{$left}em;
			right: #{$right}em;
		}
	}
}

.nc-slide-container-top {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1 1 27%;
	@media only screen and (max-width: $screen-md-min) {
		align-items: flex-start;
	}
}

.nc-slide-container-orange-title {
	font-size: 32px;
	font-family: 'TradeGothicLTStd-BoldExt';
	line-height: 42px;
	letter-spacing: -0.6px;
	text-transform: uppercase;
}

.nc-slide-container-center {
	display: flex;
	flex: 1 1 100%;
}

.nc-slide-container-center-left {
	display: flex;
	flex: 1 1 5%;
	padding-left: 101px;
	@media only screen and (max-width: $screen-md-min) {
		padding-left: 0px;
	}
}
.nc-slide-container-center-center {
	display: flex;
	flex: 1 1 90%;
	align-items: center;
}
.nc-slide-container-center-right {
	display: flex;
	flex: 1 1 5%;
	justify-content: flex-end;
	padding-right: 101px;
	@media only screen and (max-width: $screen-md-min) {
		padding-right: 0px;
	}
}

.nc-slide-container-bottom {
	flex: 1 1 27%;
	display: flex;
	align-items: center;

	@media only screen and (max-width: $screen-md-min) {
		align-items: flex-end;
	}
}

.nc-slide-container-bottom-left {
	display: flex;
	flex: 1 1 5%;
}
.nc-slide-container-bottom-center {
	display: flex;
	flex: 1 1 90%;
	align-items: center;
	justify-content: center;
	@media only screen and (max-width: $screen-md-min) {
		height: 35px;
	}
}
.nc-slide-container-bottom-right {
	display: flex;
	flex: 1 1 5%;
	justify-content: flex-end;
}
