@import '../../../styles/variables.scss';

.perfil-vehiculo-content {
	display: flex;
	padding-left: 5%;
	padding-right: 5%;
	padding-top: 32px;
	padding-bottom: 40px;
	justify-content: space-between;
	flex-grow: 1;

	@media only screen and (max-width: $screen-md-min) {
		padding-left: 5%;
		padding-right: 5%;
		min-height: auto;
		flex-direction: column;
	}
}

.perfil-vehiculo-content h3 {
	color: $dark-gray;
	font-size: $font-28;
	letter-spacing: 0;
	line-height: 36px;
	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-18;
		line-height: 27px;
	}
}

.perfil-orange-title {
	font-size: $font-20;
	color: $orange;
	letter-spacing: 0.63px;
	line-height: 24px;
	font-family: 'TradeGothicLT-BoldCondTwenty';
	margin-top: 30px;
	text-align: left;
	text-transform: uppercase;
	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-16;
		letter-spacing: 0.5px;
		line-height: 19px;
	}
}

.perfil-form-content {
	display: flex;
	flex-direction: column;
	@media only screen and (max-width: $screen-md-min) {
		width: 100%;
	}
}

.perfil-vehiculo-form-input {
	display: block;
	margin-top: 10px;
	width: 100%;
	color: $light-gray-2;
	text-align: left;
}
.perfil-vehiculo-legal-content {
	margin-top: 62px;
	@media only screen and (max-width: $screen-md-min) {
		margin-top: 30px;
	}
}
.perfil-vehiculo-legal-content p {
	color: $light-gray-2;
	font-family: 'TradeGothicLT';
	font-size: 12px;
	letter-spacing: 0;
	line-height: 12px;
	text-align: left;
	margin-top: 10px;
	@media only screen and (max-width: $screen-md-min) {
		margin-top: 20px;
		line-height: 20px;
	}
}

.perfil-vehiculo-direction-column {
	display: flex;
	flex-direction: column;
	border-right: $dot solid 1px;
	&:last-child {
		border-right: none;
	}
	@media only screen and (max-width: $screen-md-min) {
		border-right: none;
	}
}

.perfil-vehiculo-content > div {
	width: 100%;
	margin-bottom: 10px;
	align-items: center;
}

.perfil-vehiculo-left-content {
	width: 392px;
	margin-left: auto;
	margin-right: auto;
	@media only screen and (max-width: $screen-md-min) {
		width: 100%;
	}
}

.perfil-vehiculo-orange-btn {
	margin-top: 20px;
}
