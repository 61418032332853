@import '../../styles/variables.scss';

.contacto__inner {
	margin: 140px auto 80px auto;

	@media (min-width: $screen-md-min) {
		display: grid;
		column-gap: 10%;
		grid-template-columns: 40% 50%;
		margin: 80px auto;
	}

	.container-left {
		margin-bottom: 40px;

		@media only screen and (min-width: $screen-md-min) {
			margin: 0;
		}

		form {
			.contacto-textarea {
				border: 1px solid rgba(21, 13, 0, 0.25);
				border-radius: 6px;
				padding: 12px;

				:focus {
					border: 1px solid $orange;
				}
			}

			.container-subscribete-container {
				font-size: 1.4rem;
				margin: 16px 0 32px 0;
				vertical-align: middle;

				&-checkbox {
					display: inline-block;
				}
			}
		}
	}

	.container-right {
		margin-bottom: 40px;

		@media only screen and (min-width: $screen-md-min) {
			margin: 0;
		}

		.mapa {
			@media only screen and (max-width: $screen-md-min) {
				margin-left: -28px;
				margin-right: -28px;
			}
		}

		.paiz-contacto {
			margin-top: 40px;

			h3 {
				font-size: 1.8rem;
			}

			address {
				font-size: 1.6rem;
				color: $light-gray;
				font-style: normal;
			}
		}
	}
}
/*
.contacto-grow {
	display: flex;
	flex-grow: 1;
	.left {
		flex: 1 1 75%;
		margin-right: 5px;
		@media only screen and (max-width: $screen-md-min) {
			flex: 1 1 54%;
		}
	}
	.right {
		flex: 1 1 35%;
		margin-left: 5px;
	}
}
*/
