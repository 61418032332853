@import '../../../styles/variables.scss';

.perfil-hogar-content {
	display: flex;
	padding-left: 5%;
	padding-right: 5%;
	padding-top: 32px;
	padding-bottom: 40px;
	justify-content: space-between;
	flex-grow: 1;

	@media only screen and (max-width: $screen-md-min) {
		padding-left: 5%;
		padding-right: 5%;
		min-height: auto;
		flex-direction: column;
	}
}

.perfil-hogar-content h3 {
	color: $dark-gray;
	font-size: $font-28;
	letter-spacing: 0;
	line-height: 36px;
	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-18;
		line-height: 27px;
	}
}

.perfil-hogar-orange-title {
	text-align: left;
	font-size: $font-20;
	letter-spacing: 0.63px;
	line-height: 24px;
	margin-top: 30px;
	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-16;
		letter-spacing: 0.5px;
		line-height: 19px;
		margin-top: 0px;
	}
}

.perfil-form-content {
	display: flex;
	flex-direction: column;
	@media only screen and (max-width: $screen-md-min) {
		width: 100%;
	}
}

.perfil-form-columns {
	display: flex;

	.left {
		width: 100%;
		margin-right: 5px;
	}
	.right {
		width: 100%;
		margin-left: 5px;
	}
}

.perfil-content-radio-container {
	margin-left: 8px;
	margin-right: 8px;
}

.perfil-content-radio-left {
	display: flex;
	align-items: center;
	flex: 1 1 46%;
}
.perfil-content-radio-right {
	display: flex;
	justify-content: flex-start;
	flex: 1 1 50%;
}

.perfil-content-radio-label {
	font-size: 30px;
}

.perfil-hogar-form-input {
	display: block;
	margin-top: 10px;
	width: 100%;
}
.perfil-hogar-legal-content {
	margin-top: 62px;
	@media only screen and (max-width: $screen-md-min) {
		margin-top: 30px;
	}
}
.perfil-hogar-legal-content p {
	color: #999ca0;
	font-family: 'TradeGothicLT';
	font-size: 1.2rem;
	letter-spacing: 0;
	line-height: 1.6;
	text-align: left;
	margin-top: 10px;
	@media only screen and (max-width: $screen-md-min) {
		margin-top: 20px;
	}
}

.perfil-hogar-direction-column {
	display: flex;
	flex-direction: column;
	border-right: $dot solid 1px;
	&:last-child {
		border-right: none;
		flex: 1 1 120%;
	}
	@media only screen and (max-width: $screen-md-min) {
		border-right: none;
	}
}

.perfil-hogar-content > div {
	width: 100%;
	margin-bottom: 10px;
	@media only screen and (max-width: $screen-md-min) {
		margin-bottom: 0;
	}
}

.perfil-hogar-align-left {
	align-items: flex-start;
}

.perfil-hogar-left-content {
	width: 442px;
	margin-left: 50px;
	align-items: flex-start;

	@media only screen and (max-width: $screen-md-min) {
		margin-left: 0;
		width: 100%;
	}
}

.perfil-hogar-detalles-container {
	@media only screen and (max-width: $screen-md-min) {
		margin-top: 45px;
	}
}

.perfil-hogar-right-content {
	width: 392px;
	margin-top: 72px;
	@media only screen and (max-width: $screen-md-min) {
		width: 100%;
		margin-top: 0px;
	}
}

.perfil-orange-btn {
	margin-top: 60px;
	@media only screen and (max-width: $screen-md-min) {
	}
}

.perfil-grow {
	display: block;
}
.perfil-75 {
	flex-grow: 2;
}

.perfil-25 {
	flex-grow: 1;
}

.MuiFormGroup-root {
	flex-direction: row;
}
