@import '../../styles/variables.scss';
@import '../../styles/accordian.scss';
@import '../../styles/jump-nav.scss';

.detailJob{
	font-size: 1.6rem;
}

.listJob{
	font-size:1.6rem;
}

.empleos{

	.accordion__heading{

		p{
			font-size: 1.6rem;
			font-family: "TradeGothicLT";
			margin: 6px 0;

		}
			
	}

	.accordion__panel{
		p, ul{
			margin-bottom: 30px;

			&:last-child{
				margin: 0;
			}
		}

		ul{
			li:before{
				content: "\2022";
				color: $orange;
				margin-right: 6px;;
			}
		}
	}

}

.titleJobs{
	font-family: 'TradeGothicLT-Bold';
	font-size: 16px;
	font-weight: bold;
}
.blackLink{
	color: #717478;
	font-size: 1.8rem;
	font-family: "TradeGothicLT-Bold";
	letter-spacing: -.05rem;
}

.accordion__button {
	&::after {
		background: none !important;
	}
}
