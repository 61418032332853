@import '../../../styles/variables.scss';

.trabaja {
	position: relative;
	background: $dark-gray;
	padding: 60px 0;
	padding-top: 100vw;

	@media only screen and (min-width: $screen-md-min) {
		// Tablet+
		padding: 0;
	}
}

.trabaja__bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100vw;
	background: url('../../../images/home/unete_al_equipo.png') center no-repeat;
	background-size: cover;

	@media only screen and (min-width: $screen-md-min) {
		// Tablet+
		left: 50%;
		bottom: 0;
		height: auto;
	}
}

.trabaja__cols {
	display: flex;
	align-items: stretch;
	width: 100%;
	flex-wrap: wrap;
	text-align: center;
	

	& > div {
		// Cols
		width: 50%;
		
	}

	& > div .button {
		// Cols
		display: inline-block;
		
	}
}


.trabaja__cols_reviews {
	display: flex;
	align-items: stretch;
	width: 100%;
	flex-wrap: wrap;
	text-align: center;
	

	& > div {
		// Cols
		width: 100%;
		
	}

	& > div .button {
		// Cols
		display: inline-block;
		
	}
}

.trabaja__left {
	padding-top: 60px;

	@media only screen and (min-width: $screen-md-min) {
		// Tablet+
		padding: 160px 40px 160px 0;
	}

	h2 {
		font-family: 'TradeGothicLTStd-BoldExt';
		margin: 40px 0;
		@media only screen and (min-width: $screen-md-min) {
			// Tablet+
			margin: 60px 0;
		}
	}

	.orange-title {
		color: $orange-2;
		margin-bottom: 40px;
		@media only screen and (min-width: $screen-md-min) {
			// Tablet+
			margin-bottom: 0;
		}
	}
}

.right_sobre{
	.button{
		width: 100%;
		@media only screen and (min-width: $screen-md-min) {
			width: 25rem;
		}
	}
}
