.jump-nav {
	.inner {
		margin: 24px 0;
	}

	&__label {
		color: $light-gray-2;
		font-family: 'TradeGothicLT-BoldTwo';
		margin: 20px 0;
	}

	ul {
		li {
			a,
			.link {
				display: block;
				font-size: 1.6rem;
				text-transform: uppercase;
				color: $dark-gray;
				text-decoration: underline;
				font-family: 'TradeGothicLT-BoldCondTwenty';
				margin: 20px 0;
			}
		}
	}

	@media (min-width: $bp--md) {
		.inner {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0;
		}

		&__label {
			margin: 0 30px;
		}

		ul {
			display: flex;

			li {
				margin: 0 30px;

				a {
				}
			}
		}
	}
}
