@import '../../../styles/variables.scss';

.servicios {
	padding: 100px 0;

	// Mobile
	@media only screen and (max-width: $screen-md-min) {
		padding: 60px 0;
	}

	.ct-container {
		max-width: 1280px;
	}
}

.servicios__inner {
	display: flex;
	align-items: center;

	flex-wrap: wrap;

	& > div {
		width: 100%;
	}

	// Desktop
	@media only screen and (min-width: $screen-md-min) {
		padding: 80px 0;

		& > div {
			width: 50%;
		}
	}
}

.servicios__left {
	margin: 80px 0;

	@media only screen and (min-width: $screen-md-min) {
		padding-right: 100px;
	}

	h2 {
		margin-bottom: 3.2rem;
	}

	p {
		margin-bottom: 4rem;
	}
}

.servicios__item {
	height: 110px;
	display: flex;
	padding: 0 3vw;
	background-color: $white;
	box-shadow: 0 8px 32px 0 rgba(21, 13, 0, 0.1);
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid $light-gray-2;

	// Desktop
	@media only screen and (min-width: $screen-md-min) {
		margin: 40px 16% 40px 0;
		border: none;
		justify-content: start;
	}

	&.moto {
		@media only screen and (min-width: $screen-md-min) {
			// Desktop
			margin: 40px 0 40px 16%;
		}

		.servicios__item__img {
			padding: 0 6px;
		}
	}

	&.home {
		border-bottom: none;

		.servicios__item__img {
			padding: 0 5px;
		}
	}

	&__img {
		width: 80px;
		margin-right: 3vw;
		@media (min-width: $bp--md) {
			margin-right: 30px;
		}
	}

	h4 {
		font-family: 'TradeGothicLT-BoldTwo';
		font-size: 1.6rem;
		flex-grow: 1;
		margin: 0;
		line-height: 1.25;
		font-weight: normal;
		//Tablet
		@media (min-width: $bp--md) {
			font-size: 2rem;
		}
	}
}
