@import '../../styles/variables.scss';

.exp-content {
	display: flex;
	padding: 20px 0;
	justify-content: space-between;
	align-items: center;

	@media only screen and (max-width: $screen-md-min) {
		flex-direction: column-reverse;
		text-align: center;
	}

	& > div {
		flex: 0 0 50%;
		width: 100%;

		&:first-child {
			@media only screen and (min-width: $screen-md-min) {
				padding-left: 40px;
			}
		}

		&:last-child {
			img {
				width: 100%;
				padding: 0 0 0 72px;

				@media only screen and (max-width: $screen-md-min) {
					padding: 20px 50px;
				}
			}
		}
	}

	p {
		margin-top: 30px;
		@media only screen and (max-width: $screen-md-min) {
			margin-top: 20px;
		}
	}
}

.exp-reverse {
	flex-direction: row-reverse;
	@media only screen and (max-width: $screen-md-min) {
		flex-direction: column-reverse;
	}

	// Flip pading on reversed items
	div:first-child {
		padding-left: 0;
		@media only screen and (min-width: $screen-md-min) {
			padding-left: 40px;
		}
	}

	img {
		width: 100%;
		padding: 0 72px 0 0;

		@media only screen and (max-width: $screen-md-min) {
			padding: 20px 50px;
		}
	}
}

.exp-title {
	color: $dark-gray;
	font-size: $font-42;
	font-family: 'TradeGothicLT-Bold';
	line-height: 52px;
	text-align: left;
	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-22;
		text-align: center;
	}
}

.exp-subTitle {
	color: $light-gray;
	font-size: 1.8rem;
	font-family: 'TradeGothicLT';
	@media only screen and (max-width: $screen-md-min) {
		font-size: 1.6rem;
	}
}
