@import '../../../styles/variables.scss';

.cr-funnel-container {
	display: flex;
	justify-content: center;
	padding-left: 25%;
	padding-right: 25%;
	margin-bottom: 30px;
	@media only screen and (max-width: $screen-md-min) {
		padding-left: 3%;
		padding-right: 3%;
	}
}

.infoEnviada {
	font-family: 'TradeGothicLT-BoldCondTwenty';
	font-size: 1.6rem;
	color: #F15B2B;
}
