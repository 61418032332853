@import '../../../styles/variables.scss';


.aprende-mas{
	padding: 60px 0;

	@media(min-width: $bp--md){
		padding: 0;
	}
}

.products.desktop-grid{

	@media(min-width: $bp--md){
		display: grid;
		grid-template-columns: 1fr;
		text-align: center;
		margin: 40px 0;
	
		& > a{
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: column;
			padding: 20px;		
			height: 100vw;

			.top{
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: center;
				height: calc(16.66vw + 4.25rem - 20px)
			}
	
			h4{
				font-size: 2.8rem;
				font-family: 'TradeGothicLT-Bold';
				font-weight: normal;
				flex-grow: 0;
			}
			
			img.icon{
				width: 140px;
				height: auto;
				margin-bottom: 20px;
				margin-right: 0;
			}
			
			.orange-title{
				font-size:  1.8rem;
				margin-bottom: 20px;

				.arrow-desktop{
					height: 12px;
					margin-left: 8px;
				}
			}
		}
	
		// Tablet >
		@media(min-width: $bp--md){
			margin: 0;
			grid-template-columns: repeat(auto-fit, minmax(1rem, 1fr));
	
			& > a{
				height: 33.3vw;
				border-bottom: 1px solid $cool-gray;

			}
		}
	}
}
