@import '../../styles/variables.scss';

footer {
	padding-bottom: 40px;
}

.footer__inner {
	padding: 40px;
}

.nav__cols {
	display: grid;
	gap: 40px;
	grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
	margin-bottom: 40px;
}

.ft-logo-icon {
	width: 20px;
}

.ft-logo {
	width: 141px;
	height: 54px;
	background-size: (141px 54px);
	background-image: url('../../images/svg/logo-connect-orange.svg');
	background-repeat: no-repeat;
	display: block;

	@media only screen and (max-width: $screen-md-min) {
		margin-top: 40px;
	}
}

.ft-copyright {
	color: $cool-gray;
	font-size: 1.2rem;
	margin-top: 20px;
}

footer nav {
	h5 {
		margin-bottom: 16px;
	}

	li {
		line-height: 1.4;
	}

	a {
		text-decoration: none;
		color: $dark-gray;
		font-size: 1.6rem;

		&:hover {
			color: black;
		}
	}

	ul.social {
		display: flex;

		li {
			margin-right: 24px;

			a {
				padding: 0rem;
			}
		}
	}
}

.footer__country-select {
	display: flex;
	align-items: center;
	margin-bottom: 40px;
}

.ft-dropdown-select {
	min-width: 200px;
	margin-left: 5px;
	position: relative;
	display: block;
	border-bottom: 1px solid $light-gray-2;
	background-color: #fff;
	z-index: 10;

	select {
		border: none;
		outline: none;
		background: transparent;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border-radius: 0;
		margin: 0;
		display: block;
		width: 100%;
		padding: 12px 55px 8px 0px;
		font-size: $font-16;
		color: $dark-gray;
		font-family: 'TradeGothicLT-Bold';
	}

	&:after {
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		line-height: 38px;
		content: url('../../images//svg/chevron-orange.svg');
		text-align: center;
		color: $orange;
		font-size: 24px;
		z-index: -1;
	}
}
