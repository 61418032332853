@import '../../styles/variables.scss';

$dotSize: 0.3;
$dotMargin: 0.5;
$duration: 0.3s;
$timingEase: cubic-bezier(0.51, 0.92, 0.24, 1);
$timingBounce: cubic-bezier(0.51, 0.92, 0.24, 1.15);

.funnel-slide-container__dot {
	display: block;
	margin: 0 #{$dotMargin}em;
	width: #{$dotSize}em;
	height: #{$dotSize}em;
	background: $dot;
	border-radius: 100px;
}
