label.MuiFormLabel-root {
	font-size  : 1.6rem;
	color      : #999ca0;
	font-family: 'TradeGothicLT';
	text-align : left;
}

label.MuiFormLabel-root.MuiInputLabel-shrink {
	color      : #999ca0;
	font-family: 'TradeGothicLT-Bold';
}

input.MuiInputBase-input {
	font-size: 1.6rem;
}

.stripeInput {
	padding   : 20px 12px 10px;
	font-size : 1.6rem;
	box-sizing: content-box;
	width     : 100%;
	display   : inline-block !important;
	margin-top: 24px;
}

.tooltip {

	&__container {
		cursor             : pointer;
		color              : #ca651a;
		position           : absolute;
		right              : 10px;
		top                : 41px;
		-moz-user-select   : none;
		-webkit-user-select: none;
		-ms-user-select    : none;
		user-select        : none;

		&:hover {
			.tooltip__text {
				visibility: visible;
			}
		}
	}

	&__text {
		visibility      : hidden;
		width           : max-content;
		background-color: #f6f8fa;
		box-shadow      : 0 8px 32px 0 rgba(21, 13, 0, 0.1);
		color           : #717478;
		text-align      : center;
		border-radius   : 6px;
		padding         : 6px;
		position        : absolute;
		z-index         : 1;
		top             : -16px;
		font-size       : 11px;
		right           : 110%;
		max-width       : 200px;
	}
}