@import '../../../styles/variables.scss';

.-confirmacion {
	.illustration {
	}
}

.confirmation-content {
	display: flex;
	padding-left: 5%;
	padding-right: 5%;
	justify-content: space-between;
	flex-grow: 1;

	@media only screen and (max-width: $screen-md-min) {
		padding-bottom: 50px;
		padding-left: 5%;
		padding-right: 5%;
		min-height: auto;
		flex-direction: column-reverse;
	}
}

.confirmation-content h3 {
	color: $dark-gray;
	font-size: $font-28;
	letter-spacing: 0;
	line-height: 36px;
	@media only screen and (max-width: $screen-md-min) {
		text-align: center;
		font-size: $font-18;
		line-height: 27px;
		text-align: center;
		margin-top: 45px;
	}
}

.confirmation-icon {
	margin-bottom: 28px;
	text-align: left;
	@media only screen and (max-width: $screen-md-min) {
		text-align: center;
		margin-bottom: 30px;
	}
}

.confirmation-direction-column {
	display: flex;
	flex-direction: column;

	& > img {
		width: 100%;
		max-width: 700px;
		height: auto;
	}
}

.confirmation-content > div {
	width: 100%;
	align-items: center;
	display: flex;
	justify-content: center;
}

.confirmation-content p {
	font-family: 'TradeGothicLT';
	color: $light-gray;
	font-size: $font-18;
	letter-spacing: 0;
	line-height: 28px;
	margin-top: 12px;
	@media only screen and (max-width: $screen-md-min) {
		text-align: center;
		font-size: $font-16;
		line-height: 28px;
		text-align: center;
		margin-top: 10px;
	}
}

.confirmation-necesitas-ayuda {
	font-family: 'TradeGothicLT';
	font-size: $font-18;
	letter-spacing: 0;
	line-height: 28px;
	text-align: left;
	margin-top: 26px;
	color: $light-gray;
	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-16;
		text-align: center;
		letter-spacing: 0;
		line-height: 24px;
	}
}

.confirmation-contactenos {
	font-family: 'TradeGothicLT';
	font-size: $font-18;
	letter-spacing: 0;
	line-height: 28px;
	text-align: left;
	margin-top: 6px;
	color: $orange;
	display: block;
	text-decoration: underline;
	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-16;
		text-align: center;
		letter-spacing: 0;
		line-height: 24px;
	}
}
.confirmation-left-content {
	width: 392px;
	@media only screen and (max-width: $screen-md-min) {
		width: 100%;
	}
}

.confirmation-green-btn {
	margin-top: 60px;
	background-color: $green;
	@media only screen and (max-width: $screen-md-min) {
		margin-top: 40px;
	}
}
