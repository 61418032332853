@import '../styles/variables.scss';

.about__banner{

	background: url('../images/jeep.aerial.coast.jpg') center no-repeat;
	background-size: cover;
	background-position:  center;
	background-repeat: no-repeat;
	padding: 60px 0 140px 0;

	h2{
		color: $white;
		font-size: 2.6rem;
		line-height: 1.25;
	}	

	
	@media(min-width: $bp--md) {
		display: flex;
		align-items: center;
		height: 560px;
		padding-top: 0;
		padding-bottom: 80px;

		h2{
			font-size: 6rem;
		}
	}


}

.sobre-nosotros{
	padding-top: 60px;
	padding-bottom: 100px;

	.item{
		margin: 60px 0;
	}

	.img{
		padding: 0 50px 35px;
	}

	@media(min-width: $bp--md){
		padding-top: 130px;
		padding-bottom: 210px;

		.item{
			display: flex;
			margin: 150px 0;
			align-items: center;
		}

		.copy{
			width: 50%;
		}
	
		.img{
			width: 50%;
			padding: 0 120px 0 0;
		}

		.item.reverse{
			flex-direction: row-reverse;
	
			.img{
				padding: 0 0 0 120px;

			}
		}
	}

	#mission{

		h2{
			font-size: 2.2rem;
			line-height: 1.45;
			text-align: left;
			
		}
	
		@media(min-width: $bp--md){
			margin-top: 140px;
	
			h2{
				font-size: 4.8rem;
				line-height: 1.25;
			}
		}
	}

}

.left_sobre{
	@media only screen and (min-width: $screen-md-min) {
		// Tablet+
		left: 50%;
		bottom: 0;
		height: auto;
	}
}

#reviews{
	background-color: $light-gray-3;
	padding: 140px 0;

	.ct-container{
		display: grid;
	
	}



	.reviews__rating{
		font-family: 'TradeGothicLT-BoldCondTwenty';
		color: $dark-gray;
		line-height: 1.25;

		.upper{
			font-size: 15rem;
		}

		.lower{
			font-size: 7.5rem;
		}
		
	}

	.reviews__stars{
		img{
			margin: 0 4px;
		}
		
		@media(max-width: $bp--md){
			img{
				width: 30rem;
			}
		}
	}

	.reviews__count{
		margin-top: 10px;
		margin-bottom: 70px;
	}

	h2{
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 40px;


	}
}