@import '../../../styles/variables.scss';

.productos-buy-container {
	width: calc(100% - 60px);
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	margin-top: -80px;
	margin-bottom: 80px;
	z-index: 4;
	background-color: white;
	text-align: center;
	box-shadow: 0 8px 32px 0 rgba(21, 13, 0, 0.1);
	
	@media (min-width: $bp--lg) {
		position: sticky;
		position: -webkit-sticky;
		float: right;
		right: calc(50% - 600px);
		margin-right: 20px;
		top: 70px;
		margin-top: -400px;
		margin-bottom: 40px;
		z-index: 12;
	}


	&-padding {
		padding: 20px;

		@media(min-width: $bp--lg){
			padding: 40px;
		}
	}
	&-icon {

		img{
			width: 150px;
			height: auto;
		}
	}
	&-h3 {
		margin-top: 27px;
		text-align: center;
		color: $dark-gray;
		align-items: center;
		white-space: nowrap;
		@media only screen and (max-width: $screen-md-min) {
			font-size: 2.2rem;
			margin-left: auto;
			margin-right: auto;
		}
	}
	&-info {
		font-size: 1.6rem;
		letter-spacing: 0;
		line-height: 1.8;
		text-align: center;
		font-family: 'TradeGothicLT';
		color: $light-gray;
		max-width: 328px;
		margin-top: 5px;
	}

	&-price {
		font-size: 18px;
		letter-spacing: 0;
		line-height: 28px;
		text-align: center;
		color: $light-gray;
		& span {
			font-size: $font-24;
			font-family: 'TradeGothicLTStd-BoldExt';
		}
	}

	&-btn {
		margin-top: 25px;
		width: 100%;
	}
}