@import '../../../styles/variables.scss';

.-pago {
	.radio-discount {
		margin-left: 0;
	}
}

.pago-content {
	@media (min-width: $screen-md-min) {
		display: flex;
		justify-content: space-between;
		flex-grow: 1;
	}
}

.pago-content h3 {
	color: $dark-gray;
	margin-bottom: 20px;
	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-18;
		margin-bottom: 10px;
	}
}

.pago-direction-column {
	display: flex;
	flex-direction: column;
	border-right: $dot solid 1px;
	&:last-child {
		border-right: none;
	}
	@media only screen and (max-width: $screen-md-min) {
		border-right: none;
	}
}

.pago-content > div {
	width: 100%;
	margin-bottom: 10px;
	align-items: center;
}

.pago-p {
	font-size: $font-16;
	padding: 0px;
	margin: 0px;
	margin-top: 18px;
	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-14;
		text-align: left;
	}
}

.pago-form-content {
	display: flex;
	flex-direction: column;
	@media only screen and (max-width: $screen-md-min) {
		width: 100%;
	}
}

.pago-total {
	font-size: 1.13em;
	letter-spacing: 0;
	line-height: 36px;
	font-family: 'TradeGothicLTStd-BoldExt';
	color: $dark-gray;
	text-align: left;
	margin-top: 20px;
}

.pago-total span {
	font-family: 'TradeGothicLTStd-Bold';
}

.pago-content-fix {
	height: 58px;
	width: 292px;
	@media only screen and (max-width: $screen-md-min) {
		width: 100%;
	}
}

.pago-orange-btn {
	margin-top: 60px;
	@media only screen and (max-width: $screen-md-min) {
		margin-top: 40px;
	}
}

.pago-grow {
	display: flex;
	flex-grow: 1;
	flex-wrap: wrap;
	margin: 0 -6px;

	.left {
		flex: 1 1 calc(33% - 12px);
		margin: 0 6px;
	}
	.right {
		flex: 0 1 calc(33% - 12px);
		margin: 0 6px;
	}
}

.pago-terminos-div {
	display: flex;
}

.pago-terminos-label {
	margin-top: 7px;
	font-size: 12px;
	a {
		color: inherit;
	}
}

.upper{
	text-transform: uppercase;
}

.iconInfo{
    width: 2.4rem;
    line-height: revert;
    vertical-align: top;
}
