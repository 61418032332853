@font-face {
	font-family: 'TradeGothicLTCom-BdCn20Obl';
	src: url('./fonts/TradeGothicLTCom-BdCn20Obl/font.woff2') format('woff2'),
		url('./fonts/TradeGothicLTCom-BdCn20Obl/font.woff') format('woff');
}
@font-face {
	font-family: 'TradeGothicLTStd-Extended';
	src: url('./fonts/TradeGothicLTStd-Extended/font.woff2') format('woff2'),
		url('./fonts/TradeGothicLTStd-Extended/font.woff') format('woff');
}
@font-face {
	font-family: 'TradeGothicLT';
	src: url('./fonts/TradeGothicLT/font.woff2') format('woff2'), url('./fonts/TradeGothicLT/font.woff') format('woff');
}
@font-face {
	font-family: 'TradeGothicLT-Bold';
	src: url('./fonts/TradeGothicLT-Bold/font.woff2') format('woff2'),
		url('./fonts/TradeGothicLT-Bold/font.woff') format('woff');
}
@font-face {
	font-family: 'TradeGothicLTStd-BoldExt';
	src: url('./fonts/TradeGothicLTStd-BoldExt/font.woff2') format('woff2'),
		url('./fonts/TradeGothicLTStd-BoldExt/font.woff') format('woff');
}
@font-face {
	font-family: 'TradeGothicLT-BoldTwo';
	src: url('./fonts/TradeGothicLT-BoldTwo/font.woff2') format('woff2'),
		url('./fonts/TradeGothicLT-BoldTwo/font.woff') format('woff');
}
@font-face {
	font-family: 'TradeGothicLT-BoldCondTwenty';
	src: url('./fonts/TradeGothicLT-BoldCondTwenty/font.woff2') format('woff2'),
		url('./fonts/TradeGothicLT-BoldCondTwenty/font.woff') format('woff');
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font: 10px/1.625 'TradeGothicLT', 'Trebuchet MS', Helvetica, sans-serif;
}

body {
	font-size: 1.8em;
	color: #717478;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
