@import '../../../styles/variables.scss';

header.desktop {
	display: none;
}

@media only screen and (min-width: $bp--desktop-nav) {
	header.header--mobile {
		display: none;
	}

	header.desktop {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		z-index: 9;
		height: 100px;
		padding: 0 $margin;

		nav {
			margin: 0;
			padding: 0;
			list-style: none;

			a {
				// All <a> tags
				display: block;
				padding: 1.5rem 2rem;
				color: $dark-gray;
			}

			ul.top {
				// Top Level
				display: flex;
				justify-content: space-between;
				width: 100%;
				max-width: 500px;

				li.top {
					white-space: nowrap;
					position: relative;
					line-height: 1;

					& > a {
						font-size: 2rem;

						text-transform: uppercase;
						font-family: 'TradeGothicLT-BoldCondTwenty';
						line-height: inherit;

						&:hover {
							color: $orange-2;
							transition: color $fast;
						}
					}

					&.dd {
						& > a {
							// dropdown indicator
							background: url('../../../images//svg/chevron-orange.svg') center right no-repeat;
							background-size: 1rem auto;
						}

						&:hover {
							// open sub nav

							ul.sub {
								height: auto;
								opacity: 1;
							}
						}
					}
				}
			}

			ul.sub {
				// Second Level
				height: 0;
				overflow: hidden;
				position: absolute;
				top: 5rem;
				background-color: $white;
				min-width: 24rem;
				opacity: 0;
				transition: opacity $fast;
				box-shadow: 0 8px 32px 0 rgba(21, 13, 0, 0.1);

				li {
					border-bottom: 1px solid $cool-gray;
					&:last-child {
						border: none;
					}

					a {
						padding-top: 2rem;
						padding-bottom: 2rem;
						transition: color $fast;

						&:hover {
							color: $orange-2;
						}
					}
				}
			}
		}

		a.button {
			width: auto;
			padding: 1.2rem 40px;
			margin-right: 20px;
		}
	}

	.logo {
		img {
			display: block;
			width: 140px;
		}
	}

	.header__col {
		display: flex;
		flex: 1;

		&.left {
			margin-right: auto;
			justify-content: flex-start;
			flex-shrink: 0;
		}

		&.center {
			flex-wrap: nowrap;
			justify-content: center;
			flex-grow: 2;
		}

		&.right {
			justify-content: flex-end;
			margin-left: auto;
			height: 50px;
			align-items: center;
		}
	}
} // END Media Query
