@import '../../../styles/variables.scss';

.tu-seguridad {
	max-width: 1080px; // lines up margins with section above
	margin-top: 20px;
	margin-bottom: 20px;
	

	@media(min-width: $bp--md){
		margin-top: 150px;
		margin-bottom: 150px;
	}

	.exp-content {
		div:first-child {
			padding-left: 0;
		}
	}
}
