@import '../../../styles/variables.scss';

.service-slide {
	background-image: url('../../../images/services/car-driving-on-coastal-road.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 60px 0 140px 0;
	
	@media(min-width: $bp--md) {
		display: flex;
		align-items: center;
		height: 560px;
		padding-top: 0;
		padding-bottom: 80px;
	}
	h2{
		color: $white;
		font-size: 2.6rem;
		letter-spacing: -0.8px;
		line-height: 1.25;
		margin: 0;
		@media only screen and (min-width: $screen-md-min) {
			font-size: 6rem;
			max-width: 13.9em;
		}
	}
}
