@import '../../styles/variables.scss';
.ct-container,
.container {
	margin: 0 auto;
	width: 100%;
	max-width: 1200px;
	padding: 0 28px;

	@media (min-width: $screen-md-min) {
		padding: 0 40px;
	}
}
