@import '../../../styles/variables.scss';

.cs-content {
	width: 100%;
	padding: 50px;
	overflow-x: auto;

	ul {
		text-align: center;
		list-style: none;
		white-space: nowrap;

		li {
			display: inline-block;
			margin: 0 40px;
			vertical-align: middle;

			@media (min-width: $bp--md) {
				margin: 0 4vw;
			}

			img {
				height: 45px;
				width: auto;
			}
		}
	}
}
