@import '../../../styles/variables.scss';

.radio-button {
	padding: 14px 5% 14px 8%;
	border-radius: 10px;
	width: 50%;

	&:hover {
		cursor: pointer;
	}
}

.radio {
	display: flex;
	align-items: center;

	.radio-indicator {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 1.6rem;
		width: 1.6rem;
		border-radius: 1rem;
		border: 1px solid $cool-gray;
		background-color: $white;
		margin-right: 10px;
	}

	.radio-label {
		font-family: 'TradeGothicLT-BoldCondTwenty';
		color: $light-gray-2;
		font-size: 1.2rem;
		text-transform: uppercase;
	}
}

// Add Orange Dot on Active
.radio-button.active {
	.radio-indicator::after {
		content: '';
		background: $orange;
		width: 0.6rem;
		height: 0.6rem;
		border-radius: 0.3rem;
	}
}

.radio-qty {
	font-family: 'TradeGothicLTStd-BoldExt';
	font-size: 1.8rem;
	line-height: 1;
	color: $dark-gray;
	margin-top: 12px;

	@media only screen and (min-width: $screen-sm-min) {
		font-size: 2.2rem;
	}

	@media only screen and (min-width: $screen-md-min) {
		font-size: 2.5rem;
	}
}

.radio-discount {
	color: $green;
	font-size: 0.72em;
	text-align: left;
	text-transform: none;
}

.active-indicator {
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	width: 50%;
	left: 0;
	z-index: -9;
	border: 1.5px solid $orange;
	border-radius: 11px;
	background-color: $white;
	transition: left 300ms ease-in-out;
}

.radio-button.active .active-indicator {
	left: 50%;
}
