@import '../../../styles/variables.scss';

.nuestra-garantia {
	background-color: $light-gray--;
	padding: 60px 0;
	border-top: 1px solid $cool-gray;
	
	@media(min-width: $bp--lg){
		padding: 80px 0;
	}

	.container {
		max-width: 1280px;
	}

	h2 {
		margin-bottom: 60px;
	}

	&__cols {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		& > div {
			margin: 20px 40px;
			flex: 1 0 260px;
		}

		img {
			width: 80%;
			max-width: 300px;
			margin-bottom: 20px;
		}

		h3 {
			font-size: 2.3rem;
			margin-bottom: 0.75em;
		}

		p {
			font-size: 1.6rem;
		}
	}

	/*
	h4 {
		color: $dark-gray;
		font-size: 23px;
		letter-spacing: 0;
		line-height: 36px;
		text-align: center;
		font-family: 'TradeGothicLTStd-BoldExt';
		margin-top: 25px;
		@media only screen and (max-width: $screen-md-min) {
			margin-top: 15px;
			font-size: $font-22;
			letter-spacing: -0.31px;
			line-height: 32px;
		}
	}
*/
}
